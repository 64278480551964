/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { NotificationsContext, NotificationsState } from "../../notifications/NotificationsContext";
import { ApplicationPaths } from "../../app/ApplicationPaths";

export interface NotificationNavItemProps {
  onClick(): void;
  isActive: boolean;
}

export function NotificationNavItem(props: NotificationNavItemProps): ReactElement {
  const { notificationCount } = useContext<NotificationsState>(NotificationsContext);
  const intl = useIntl();

  return (
    <NavItem
      className={classnames({
        active: props.isActive,
      })}
      aria-label={intl.formatMessage({ id: "notifications" })}
    >
      <NavLink
        tag={Link as any}
        {...{ to: ApplicationPaths.Notifications.Home }}
        data-badge={notificationCount < 10 ? notificationCount.toString() : "9+"}
        className={notificationCount > 0 ? "has-badge" : ""}
        title={intl.formatMessage({ id: "notifications" })}
        onClick={props.onClick}
        aria-label={intl.formatMessage({ id: "notifications" })}
      >
        <FontAwesomeIcon className="me-1" icon={faBell} />
        <span className="d-inline d-sm-none">{intl.formatMessage({ id: "notifications" })}</span>
      </NavLink>
    </NavItem>
  );
}
