import React, { ReactElement, useContext } from "react";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { Loading } from "../components";
import { ApplicationErrorState } from "../components/ApplicationError/ApplicationErrorState";
import { ApplicationErrorContext } from "../components/ApplicationError/ApplicationErrorContext";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { attachWindowErrorHandlers } from "../utils/errorHelpers";
import { SubscriptionExpiredApp } from "./SubscriptionExpiredApp";
import { AccessDeniedApp } from "./AccessDeniedApp";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { SessionExpiredApp } from "./SessionExpiredApp";
import "@labarchives/ui-design/dist/styles/colors.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./App.scss";

export function App(): ReactElement {
  const authState = useContext<AuthenticationState>(AuthenticationContext);
  const errorState = useContext<ApplicationErrorState>(ApplicationErrorContext);

  attachWindowErrorHandlers(errorState.setError);

  if (errorState.isError) {
    return <ErrorBoundary hasError={errorState.isError} errorReportId={errorState.errorReportId} />;
  }

  if (authState.isLoading) {
    return <Loading id="application-loading" />;
  }

  if (authState.isSessionExpired()) {
    return <SessionExpiredApp />;
  }

  if (!authState.isAuthenticated()) {
    return <UnauthenticatedApp />;
  }

  if (authState.isAccessDenied()) {
    return <AccessDeniedApp />;
  }

  if (authState.hasSubscriptionExpired()) {
    return <SubscriptionExpiredApp />;
  }

  return <AuthenticatedApp />;
}
