import React, { ReactElement, useContext } from "react";
import { InventoryApiClient } from "../api";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { NotificationsContext, useNotificationsContext } from "./NotificationsContext";

export const NotificationsProvider = (props: React.PropsWithChildren<unknown>): ReactElement => {
  const authState = useContext(AuthenticationContext);

  const notificationsContextValue = useNotificationsContext(new InventoryApiClient(), authState.getUser());
  return <NotificationsContext.Provider value={notificationsContextValue}>{props.children}</NotificationsContext.Provider>;
};
