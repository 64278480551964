import { LoginContext, User } from "@labarchives/inventory-shared/build/inventory";
import { Settings } from "../../utils/Settings";
import { ACCOUNT_ID_COOKIE_NAME, CSRF_COOKIE_NAME, deleteCookie, getCookie, setCookie } from "../../utils/cookies";
import { AuthenticationApi, AuthenticationService } from "./AuthenticationService";

const CSRF_HEADER = "X-CSRF-Token";
const ACCOUNT_ID_HEADER = "X-LabArchives-Inventory-AccountId";

export class SingleSignOnAuthenticationService implements AuthenticationService {
  public async signIn(api: AuthenticationApi, loginContext: LoginContext): Promise<User | undefined> {
    // api is injected to prevent circular dependency
    const user = await api.signUserIn(loginContext);
    if (user) {
      setCookie(ACCOUNT_ID_COOKIE_NAME, `${user.activeAccountId}`);
    }
    return user;
  }

  public setCsrfToken(token: string): void {
    setCookie(CSRF_COOKIE_NAME, token);
  }

  public isAuthenticated(): boolean {
    const csrfToken = getCookie(CSRF_COOKIE_NAME);
    return csrfToken !== undefined;
  }

  public logout(): void {
    deleteCookie(CSRF_COOKIE_NAME);
    deleteCookie(ACCOUNT_ID_COOKIE_NAME);
    const currentUrl = Settings.getBaseInventoryWebUrl();
    const returnTo = encodeURI(currentUrl);
    window.location.href = `${Settings.getAccountUrlBase()}/logout?return=${returnTo}`;
  }

  public getHeaders(): unknown {
    const csrfToken = getCookie(CSRF_COOKIE_NAME);
    return {
      [CSRF_HEADER]: `${csrfToken}`.replace(" ", "+"),
      [ACCOUNT_ID_HEADER]: getCookie(ACCOUNT_ID_COOKIE_NAME),
    };
  }

  public async getSecondsRemainingInSession(api: AuthenticationApi): Promise<number> {
    const response = await api.pingSession();
    return response.secondsRemainingInSession;
  }

  public async extendSession(api: AuthenticationApi): Promise<void> {
    await api.extendSession();
  }
}
