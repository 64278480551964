import React, { ReactElement, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { Button, Card, CardBody, CardHeader } from "@labarchives/ui-design";
import "./Notifications.scss";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { onPageLoad } from "../utils/onPageLoad";
import { NotificationsContext, NotificationsState } from "./NotificationsContext";
import { NotificationCard } from "./NotificationCard";

export function Notifications(): ReactElement {
  const authState = useContext(AuthenticationContext);

  const { notifications, onDismissNotification, onDismissAllNotifications, onNotificationsFetched } =
    useContext<NotificationsState>(NotificationsContext);
  const intl = useIntl();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.notifications" }));
  }, []);

  useEffect(() => {
    onNotificationsFetched();
  }, []);

  return (
    <Card>
      <CardHeader className="notifications-list-header">
        <div className={"d-flex align-items-center"}>
          <h1 className="inventory-heading">
            <FormattedMessage id="notifications" />
          </h1>
          <Badge role={"status"} aria-label={intl.formatMessage({ id: "notifications.badge" }, { count: notifications.length })}>
            {notifications.length}
          </Badge>
        </div>
        <div className="notifications-dismiss-all">
          <Button color="link" onClick={onDismissAllNotifications} title={intl.formatMessage({ id: "dismiss.all" })}>
            <FormattedMessage id="dismiss.all" />
          </Button>
        </div>
      </CardHeader>
      <CardBody className="notifications-list-body">
        {[...notifications]
          .sort((n1, n2) => n2.createdAt.getTime() - n1.createdAt.getTime())
          .map((n) => (
            <NotificationCard key={n.id} notification={n} onDismissNotification={onDismissNotification} currency={authState.getCurrency()} />
          ))}
      </CardBody>
    </Card>
  );
}
